<template>
  <main>
    <section class="pt-3 bg-light-gray steps">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-7 text-center my-5">
            <p class="step error">
              <img src="@/assets/icons/icon_error.svg" alt="Error" />
            </p>
            <p class="text-danger d-block">
              {{ $t("inspection.error.message") }}
            </p>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { mapActions } from "vuex";

export default {
  computed: {},
  methods: {
    ...mapActions({
      GET_QUESTIONS: "inspection/GET_QUESTIONS",
    }),
  },
};
</script>
